<template>
  <div class="body-bg">
    <div class="container">
      <van-nav-bar
        title="我的队伍"
        left-arrow
        @click-left="goBack"
        @click-right="goHome"
      >
        <template #right>
          <van-icon
            class="iconfont"
            class-prefix="icon"
            name="index-o"
            size="16"
          />
        </template>
      </van-nav-bar>
    </div>
    <div class="team-item" v-for="(item, index) in teamList" :key="index">
      <div class="avator">
        <img :src="item.staff_img" />
      </div>
      <div class="info">
        <div class="header-box">
          <div class="p-header">
            <p class="p-name">{{ item.staff_name }}</p>
            <div class="p-rank spaceL">{{ item.grade_name }}代办</div>
          </div>
          <div class="button">
            <van-icon name="delete" size="16px" color="#b3b3b3" />
          </div>
        </div>
        <div class="p-item">
          <span v-for="(item, index) in item.order_service_arr" :key="index">
            {{ item }}
          </span>
        </div>

        <div class="p-t-item">累计金额: {{ item.staff_allmoney }}元</div>
      </div>
    </div>
  </div>
</template>

<script>
import Service from "@/api/service";
//import { Toast } from "vant";
export default {
  data() {
    return {
      teamList: [],
    };
  },
  async beforeCreate() {
    var token = this.$cookie.get("token");
    //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
    var params = {
      token: token,
    };
    const resp1 = await Service.getStaffFins(this, params);

    if (resp1.data.code == 10000) {
      this.teamList = resp1.data.data;
    }
  },
  methods: {
    goBack: function() {
      this.$router.go(-1);
    },
    goHome: function() {
      this.$router.push("/");
    },

    toTeam: function() {
      this.$router.push("/person/invite/team");
    },
    toTeamOrder: function() {
      this.$router.push("/person/invite/teamOrder");
    },
    saveQCode() {
      var that = this;
      this.longClick = 0;
      this.timeOutEvent = setTimeout(function() {
        that.longClick = 1;
        that.downImg();
      }, 500);
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
}
.team-item {
  margin: 5px 5px;
  padding: 10px 10px;
  /*box-shadow: 0px 0px 4px 1px rgba(223, 223, 223, 0.45);*/
  background: #fff;
  border-radius: 4px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.team-item .avator {
  width: 90px;
  height: 90px;
}
.avator img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.team-item .info {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
  width: calc(100% - 100px);
  margin-right: 0;
}
.info .header-box {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.info .p-item {
  margin: 4px 10px;
  margin-left: 0;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  color: #b3b3b3;
}
.info .p-item span {
  margin-right: 10px;

}
.info .p-t-item {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #f5c204;
}
.header-box .p-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.p-header .p-name {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}
.p-header .p-rank {
  background: #fff6d3;
  border: 1px solid #f5c204;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 10px;
  color: #f5c204;
  border-radius: 2px;
}
</style>
